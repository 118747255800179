import React from 'react'
import Grid from '@mui/material/Grid';
import { Outlet } from "react-router-dom";
import Appbar from '../../components/Appbar/Appbar';

const Storage = () => {
    return (
        <Grid item xs={12}>
        <div style={{marginTop: '100px'}}>
            This is storage page.
        </div>
        <Appbar />
        <Outlet />
        </Grid>
    )
}

export default Storage
