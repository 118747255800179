import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './pages/App/App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Functions from './pages/Functions/Functions';
import Storage from './pages/Storage/Storage';
import About from './pages/About/About';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
        <Route path="/" element={<About />} />
        <Route path="functions" element={<Functions />} />
        <Route path="storage" element={<Storage />} />
        <Route path="about" element={<About />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();