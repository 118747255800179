import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
// import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Appbar from '../../components/Appbar/Appbar';
import { Outlet } from "react-router-dom";
import { purple } from '@mui/material/colors'
import { color } from '@mui/system';


const theme = createTheme({
  palette: {
    primary: {
      main: '#fefefe'
    },
    secondary: purple
  },
  typography: {
    fontFamily: 'Manrope',
    fontWeightLight: 400,
    fontWeightRegular:500,
    fontWeightMedium:600,
    fontWeightBold:700
  }
});

var cardStyle = {
  display: 'block',
  width: '45vw',
  // transitionDuration: '0.3s',
  height: '40vw',
  maxHeight: 380,
  maxWidth: "100%"
  
}

export default function Album() {
  return (
    
    <ThemeProvider theme={theme}>
      <Appbar />
      {/* <Outlet /> */}

      <CssBaseline />
      <Grid item xs={12}>
      {/* <AppBar position="relative">
        <Toolbar>
          <CameraIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Album layout
          </Typography>
        </Toolbar>
      </AppBar> */}
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              I'm Warren Choi, a
              <br></br>
              Data Engineer
            </Typography>
            <Typography variant="h5" align="center" color="text.secondary" paragraph>
              I use this site for my personal projects.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <a href="https://www.linkedin.com/in/warrenychoi/" style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
                <Button variant="contained" style={{
                    backgroundColor: "#0072b1",
                    padding: "36x",
                    fontSize: "14px",
                    color: '#fefefe'
                  }}
                >
                  LinkedIn
                </Button>
              </a>
            </Stack>
          </Container>
        </Box>
        
        <Container sx={{ py: 0 }} maxWidth="md" >
          {/* End hero unit */}
          <Grid container spacing={6} >
          <Grid item xs={6}>
                <Card style={cardStyle}
                  // elevation={24}
                  elevation={2}
                  sx={{ height: '65%', display: 'flex', borderRadius: '50px' }}
                >
                  <CardMedia
                    component="img"
                    // image="https://source.unsplash.com/random"
                    image={"IMG_947493.jpg"}
                    alt="random"
                  />

                  {/* <img src="public\IMG_9367482.jpg" alt=""/> */}

                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card 
                    // style={cardStyle}
                    style={{
                      // backgroundColor: "#ffe8d2",
                      alignItems: 'center', 
                      justifyContent:'center'
                    }}
                  // elevation={24} 
                  elevation={4}
                  sx={{ height: '100%', display: 'flex', borderRadius: '50px' }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography align='center' gutterBottom variant="h5" component="h2">
                        {/* Card3 */}
                      </Typography>

                      <Typography align="center"
                        style={{
                          // backgroundColor: "#0072b1",
                          // padding: "36x",
                          fontSize: "20px",
                          // color: '#fefefe'
                        }}
                      >
                        I live in the San Francisco Bay Area, enjoying the great food and nature.
                      </Typography>
                    </CardContent>
                  </Card>

              </Grid>

              <Grid item xs={6} >
                <Card 
                  // style={cardStyle, 
                  style={{
                    // backgroundColor: "#ffe8d2",
                    alignItems: 'center', 
                    justifyContent:'center'
                  }}
                  // elevation={24} 
                  elevation={4}
                  sx={{ height: '100%', display: 'flex', borderRadius: '50px' }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    {/* <Typography align='center' gutterBottom variant="h5" component="h2">
                      Card3
                    </Typography> */}

                    <Typography align="center"
                    style={{
                      // backgroundColor: "#0072b1",
                      padding: "36x",
                      fontSize: "20px",
                      // color: '#fefefe'
                    }}>
                      I have a Siamese cat and also foster kittens from time to time.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>


              <Grid item xs={6}>
                <Card style={cardStyle}
                    // elevation={24}
                    elevation={2}
                    sx={{ height: '65%', display: 'flex', borderRadius: '50px' }}
                  >
                    <CardMedia
                    component="img"
                    image={"IMG_365849.jpg"}
                    alt="random"
                  />
                </Card>
              </Grid>

            

          </Grid>
        </Container>
      </main>

      {/* Footer */}
      {/* <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography>
      </Box> */}
      {/* End footer */}
      </Grid>
    </ThemeProvider>

  );
}